import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { URLConstants } from "services/constants/URLConstants";

interface IPublicLayout {
  children: any;
  style?: any;
}

export const signupRedirect = () => {
  return URLConstants.SIGN_UP;
};

export const loginRedirect = () => {
  return URLConstants.SIGN_IN;
};

const PublicLayout: React.FC<IPublicLayout> = ({ children, style }) => {
  const router = useRouter();
  const variants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  };
  return (
    <motion.main
      key={router.asPath}
      variants={variants} // Pass the variant object into Framer Motion
      initial="hidden" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
      transition={{
        type: "ease",
        stiffness: 260,
        damping: 20,
      }}
      style={style}
    >
      {children}
    </motion.main>
  );
};

export default PublicLayout;
